html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.appHeader {
  background-color: var(--color-canvas-default);
  border: 1px solid var(--color-border-muted);
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-fg-default);
}

.appHeader select {
  font-size: 1rem;
  padding: 0.5rem 0rem;
  color: var(--color-fg-default);
  background-color: var(--color-canvas-subtle);
  border: 1px solid var(--color-border-muted);
  border-radius: 6px;
  box-sizing: border-box;
  flex: 1;
  max-width: 180px;
}

.chatContainer {
  flex-direction: column;
  overflow-x: hidden;
  display: flex;
  flex: 1;
  position: relative;
}

.chatHistory {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  max-width: 940px;
  flex: 1;
  flex-direction: column;
  align-self: center;
}

.chatHistory .scroll {
  overflow-y: auto;
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
}

.bottomContainer {
  margin-top: auto;
  position: relative;
  max-width: 940px;
  width: 100%;
  align-self: center;
  transition: margin 0.5s ease;
  overflow-x: hidden;
  padding: 0.5rem 1rem;
  background-color: var(--color-canvas-default);
  border: 1px solid var(--color-border-muted);
  box-sizing: border-box;
  border-radius: 6px;
}

.bottomContainer form {
  display: flex;
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px transparent;
  transition: border 0.14s, box-shadow 0.14s;
}

.bottomContainer textarea {
  flex: 1;
  margin-right: 1rem;
  max-height: 200px;
  overflow-y: hidden;
  padding: 0.5rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  font-size: 1rem;
  border: 1px solid var(--color-border-default);
  background-color: var(--color-canvas-subtle);
  border-radius: 28px;
}

.bottomContainer button {
  height: 48px;
  width: 48px;
  padding: 0px;
  border-radius: 12px;
  border-color: green;
  cursor: pointer;
}

.bottomContainer button img {
  display: block;
  height: 48px;
  width: 48px;
  background-color: transparent;
}

.action-btn {
  background-color: #ff7f00;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5em 1.25em;
  color: var(--color-canvas-default);
  border-radius: 0.15em;
  transition: 0.3s;
  cursor: pointer;
  position: relative;
  display: block;
}

.action-btn:hover {
  background-color: #ff6600;
}

.action-btn:focus {
  outline: 0.05em dashed #ff6600;
  outline-offset: 0.05em;
}

.action-btn::after {
  content: '';
  display: block;
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  left: calc(50% - 0.75em);
  top: calc(50% - 0.75em);
  border: 0.15em solid transparent;
  border-right-color: var(--color-border-default);
  border-radius: 50%;
  animation: button-anim 0.7s linear infinite;
  opacity: 0;
}

@keyframes button-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.action-btn.loading img {
  visibility: hidden;
}

.action-btn.loading {
  background-color: var(--color-fg-subtle);
  color: transparent;
  cursor: unset;
}

.action-btn.loading::after {
  opacity: 1;
}

.chatBoxContainer strong {
  margin-left: 0.1rem;
  line-height: 1.5em;
}

.chatBox {
  border-radius: 4px 18px 18px 18px;
  padding: 1rem;
  padding-bottom: 0.5rem;
  background-color: var(--color-canvas-default);
  margin-bottom: 1rem;
  margin-top: 0.2rem;
}
